import React from "react";

const ScoreAvatar = ({ rank, score }) => {
  return (
    <div
      class={`relative inline-flex items-center justify-center  overflow-hidden px-8 ${
        rank === "1" && "bg-[#fde047] text-white"
      }
  ${rank === "2" && "bg-[#cbd5e1] text-white"}
  ${rank === "3" && "bg-[#854d0e] text-white"}
rounded w-7 h-7 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-10 lg:h-10 xl:w-10 xl:h-10 2xl:w-10 2xl:h-10`}
    >
      <span class="font-medium  ">{score}</span>
    </div>
  );
};

export default ScoreAvatar;
