import React from "react";

const NoDataFound = ({ message }) => {
  return (
    <div class="px-3 py-10 flex  justify-center sm:px-5 ">
      <h2 class=" font-medium leading-tight text-black italic text-2xl	">
        {message}
      </h2>
    </div>
  );
};

export default NoDataFound;
