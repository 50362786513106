import React from "react";
const rowNumber = [1, 2, 3, 4, 5];

const ContestSkeleton = () => {
  return (
    <>
      {rowNumber?.map((row, index, arr) => {
        return (
          <div class="flex mt-6 justify-center animate-pulse ">
            <div
              class="flex justify-between items-center
rounded-bl-lg rounded-tl-lg rounded-tr-lg rounded-br-lg pl-5 bg-gray-200
shadow-md h-32 w-5/6 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2"
            ></div>
          </div>
        );
      })}
    </>
  );
};

export default ContestSkeleton;
