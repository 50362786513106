import React from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "../../../constant/assests";
import { useNavigate } from "react-router-dom";
import PartnerAvatar from "../partnerAvatar";

const PartnerRow = ({ partnerList }) => {
  const navigate = useNavigate();
  return (
    <>
      {partnerList?.map((partner, index, arr) => {
        return (
          <div
            class={`mt-3 bg-white shadow-2xl relative inline-flex items-center justify-between cursor-pointer	w-full h-20 overflow-hidden px-2 rounded  sm:h-32  md:h-32  lg:h-32  xl:h-32  2xl:h-32`}
            onClick={() => navigate(`/lb/${partner?.id}`)}
          >
            <PartnerAvatar partner={partner} />

            <div className={styles.partnerName}>{partner?.name}</div>
            <div className={styles.partnerName}>
              <img
                src={IMAGES.RIGHT_ARROW}
                alt="right-arrow"
                className={styles.arrowStyle}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default PartnerRow;
