import React from "react";

const RankAvatar = ({ rank }) => {
  return (
    <>
      <div
        class={`relative inline-flex items-center justify-center w-7 h-7 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-10 lg:h-10 xl:w-10 xl:h-10 2xl:w-10 2xl:h-10 overflow-hidden ${
          rank === "1" && "bg-[#fde047] text-white"
        }
       ${rank === "2" && "bg-[#cbd5e1] text-white"}
       ${rank === "3" && "bg-[#854d0e] text-white"}  rounded-full 
       `}
      >
        <span class="font-medium ">{rank}</span>
      </div>
    </>
  );
};
export default RankAvatar;
