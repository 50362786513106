import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "../../constant/assests";
import { listApi } from "../../api/commonApi";
import { APIS } from "../../api/endpoints";
import { useNavigate } from "react-router-dom";
import LandingGridSkeleton from "../../components/skeleton/landingGridSkeleton";
import PartnerRow from "../../components/avatar/partnerRow";

const Home = () => {
  const navigate = useNavigate();

  const [partnerList, setPartnerList] = useState();
  /*API Call For Partner Data  */

  useEffect(() => {
    listApi(APIS.LIST_PARTNERS).then((result) => setPartnerList(result?.data));
  }, []);

  /*Return */

  return (
    <>
      <div className={styles.headerContainer}>
        <img src={IMAGES.LOGO} alt="Web Logo" className={styles.webLogoStyle} />
      </div>
      <div className={styles.listContainer}>
        {partnerList ? (
          <PartnerRow partnerList={partnerList} />
        ) : (
          <LandingGridSkeleton />
        )}
      </div>
    </>
  );
};

export default Home;
