import React from "react";

const BoardHeadingSkeleton = () => {
  return (
    <>
      <div class="px-3 pt-5 sm:px-5 animate-pulse bg-slate-50 flex justify-center	">
        <div class="h-9 w-6/12 font-medium leading-tight bg-gray-200 "></div>
      </div>
      <div class="px-3 pb-5 pt-1 sm:px-5 animate-pulse bg-slate-50 flex justify-center	">
        <div class="h-9 w-6/12 font-medium leading-tight bg-gray-200 "></div>
      </div>
    </>
  );
};

export default BoardHeadingSkeleton;
