import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const ContestRow = ({ contestList, partnerId }) => {
  const navigate = useNavigate();
  return (
    <>
      {contestList?.map((item, index, arr) => {
        return (
          <div
            class="flex  mt-6 justify-center  cursor-pointer	"
            onClick={() => navigate(`/cb/${item?.id}/?pid=${partnerId}`)}
          >
            <div
              class="flex w-5/6  justify-between items-center sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2
            rounded-bl-lg rounded-tl-lg rounded-tr-lg rounded-br-lg  pl-5 bg-[#ed5474]"
            >
              <div></div>
              <div class="justify-center text-white">
                <div class="font-bold	"> {item?.name}</div>

                <div class="font-light text-sm text-[#f8ccd6]">
                  {`${moment(item?.startDateTime).format(
                    "MMM DD YYYY hh:mm a"
                  )}`}
                </div>
              </div>
              <img
                class="w-32 h-34 rounded-full"
                src={item?.imageLink || item?.partnerData?.profileImage}
                alt="Rounded avatar"
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ContestRow;
