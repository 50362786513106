import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { listApi } from "../../api/commonApi";
import { APIS } from "../../api/endpoints";
import NavBar from "../../components/navBar";
import ContestRow from "../../components/contestRow";
import ContestSkeleton from "../../components/skeleton/contestRowSkeleton";
import NoDataFound from "../../components/noDataFound";
import { NO_DATA_FOUND } from "../../constant/constant";
import { CONTEST_PAGE_BUTTON } from "../../constant/constant";
import InfiniteScroll from "react-infinite-scroll-component";

const Contest = () => {
  const { id } = useParams();
  const [contestList, setContestList] = useState([]);
  const [page, setPage] = useState(0);
  const [limit] = useState(10);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  /*API Call For Partner Data  */

  const fetchData = () => {
    setLoading(true);
    listApi(APIS.LIST_CONTEST, {
      partnerId: id,
      skip: limit * page,
      limit: limit,
    })
      .then((result) => {
        setContestList([...contestList, ...result?.data]);
        setPage(page + 1);
        setCount(result?.count);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <NavBar buttonData={CONTEST_PAGE_BUTTON} />
      {loading && <ContestSkeleton />}
      {!!contestList?.length ? (
        <InfiniteScroll
          dataLength={page * 10}
          next={fetchData}
          hasMore={contestList?.length === count ? false : true}
          loader={
            <div class="px-3 py-10 flex  justify-center sm:px-5 ">
              Loading...
            </div>
          }
          height={800}
        >
          <div class="pt-10 pb-10 justify-center sm:px-5 ">
            <ContestRow contestList={contestList} partnerId={id} />
          </div>
        </InfiniteScroll>
      ) : (
        <>
          <NoDataFound message={NO_DATA_FOUND.CONTEST} />
        </>
      )}
    </>
  );
};

export default Contest;
