import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { listApi } from "../../api/commonApi";
import { APIS } from "../../api/endpoints";
import NavBar from "../../components/navBar";
import TableHeading from "../../components/tableHeading";
import LeaderBoardTable from "../../components/table/leaderBoardTable";
import LeaderBoardSkeleton from "../../components/skeleton/leaderBoardSkeleton";
import BoardHeadingSkeleton from "../../components/skeleton/boardHeadingSkeleton";
import NoDataFound from "../../components/noDataFound";
import { NO_DATA_FOUND } from "../../constant/constant";
import { PARTNER_BOARD_BUTTON } from "../../constant/constant";
import { Helmet } from "react-helmet";

const LeaderBoard = () => {
  const { id } = useParams();
  const [listLeaderBoard, setListLeaderBoard] = useState();
  const [partnerData, setPartnerData] = useState();

  /*API Call For Partner Data  */

  useEffect(() => {
    listApi(APIS.LIST_LEADERBOARD, { partnerId: id }).then((result) => {
      setListLeaderBoard(result?.data);
      setPartnerData(result?.partnerData);
    });
    const interval = setInterval(() => {
      listApi(APIS.LIST_LEADERBOARD, { partnerId: id }).then((result) => {
        setListLeaderBoard(result?.data);
      });
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  /*Return */

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {partnerData?.name ? `${partnerData?.name}  Leaderboard` : ""}
        </title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <NavBar buttonData={PARTNER_BOARD_BUTTON} />
      {listLeaderBoard?.length === 0 ? (
        <NoDataFound message={NO_DATA_FOUND.LEADER_BORAD} />
      ) : (
        <>
          {!!partnerData?.name ? (
            <TableHeading headingName={partnerData?.name} label="Overall" />
          ) : (
            <BoardHeadingSkeleton />
          )}
          {!!listLeaderBoard ? (
            <LeaderBoardTable
              partnerData={partnerData}
              listLeaderBoard={listLeaderBoard}
            />
          ) : (
            <LeaderBoardSkeleton />
          )}
        </>
      )}
    </>
  );
};
export default LeaderBoard;
