import React from "react";

const PartnerAvatar = ({ partner }) => {
  return (
    <>
      {partner?.profileImage ? (
        <img
          class="w-10 h-10 rounded-full sm:w-20 sm:h-20  md:w-20 md:h-20  lg:w-20 lg:h-20  xl:w-20 xl:h-20  2xl:w-20 2xl:h-20"
          src={partner?.profileImage}
          alt={partner?.name}
        />
      ) : (
        <div class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full sm:w-20 sm:h-20  md:w-20 md:h-20  lg:w-20 lg:h-20  xl:w-20 xl:h-20  2xl:w-20 2xl:h-20">
          <span class=" text-gray-600 sm:text-2xl	uppercase">
            {partner?.name[0]}
            {partner?.name[1]}
          </span>
        </div>
      )}
    </>
  );
};
export default PartnerAvatar;
