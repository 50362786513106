import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { listApi } from "../../api/commonApi";
import { APIS } from "../../api/endpoints";
import NavBar from "../../components/navBar";
import ContestSkeleton from "../../components/skeleton/contestRowSkeleton";
import NoDataFound from "../../components/noDataFound";
import { NO_DATA_FOUND, SERIES_PAGE_BUTTON } from "../../constant/constant";
import { CONTEST_PAGE_BUTTON } from "../../constant/constant";
import InfiniteScroll from "react-infinite-scroll-component";
import ContestRow from "../../components/contestRow";
import SeriesRow from "../../components/seriesRow";

const Series = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [seriesList, setSeriesList] = useState([]);
  const [page, setPage] = useState(0);
  const [limit] = useState(10);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  /*API Call For Partner Data  */

  const fetchData = () => {
    setLoading(true);
    listApi(APIS.LIST_SERIES, {
      partnerId: id,
      skip: limit * page,
      limit: limit,
    })
      .then((result) => {
        setSeriesList([...seriesList, ...result?.data]);
        setPage(page + 1);
        setCount(result?.count);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <NavBar buttonData={SERIES_PAGE_BUTTON} />
      {loading && <ContestSkeleton />}
      {!!seriesList?.length ? (
        <InfiniteScroll
          dataLength={page * 10}
          next={fetchData}
          hasMore={seriesList?.length === count ? false : true}
          loader={
            <div class="px-3 py-10 flex  justify-center sm:px-5 ">
              Loading...
            </div>
          }
          height={800}
        >
          <div class="pt-10 pb-10 justify-center sm:px-5 ">
            <SeriesRow contestList={seriesList} partnerId={id} />
          </div>
        </InfiniteScroll>
      ) : (
        <NoDataFound message={NO_DATA_FOUND.SERIES} />
      )}
    </>
  );
};

export default Series;
