import React from "react";
const skeletionNumber = [1, 2, 3, 4, 5];

const LandingGridSkeleton = () => {
  return (
    <>
      {skeletionNumber?.map((item, index, arr) => {
        return (
          <div
            class={`mt-3 bg-gray-200 shadow-md relative inline-flex items-center justify-between w-full h-20 overflow-hidden px-2 rounded  sm:h-32  md:h-32  lg:h-32  xl:h-32  2xl:h-32`}
          ></div>
        );
      })}
    </>
  );
};
export default LandingGridSkeleton;
