import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { listApi } from "../../api/commonApi";
import { APIS } from "../../api/endpoints";
import NavBar from "../../components/navBar";
import TableHeading from "../../components/tableHeading";
import LeaderBoardTable from "../../components/table/leaderBoardTable";
import LeaderBoardSkeleton from "../../components/skeleton/leaderBoardSkeleton";
import BoardHeadingSkeleton from "../../components/skeleton/boardHeadingSkeleton";
import NoDataFound from "../../components/noDataFound";
import { NO_DATA_FOUND, SERIES_BOARD_BUTTON } from "../../constant/constant";

const SeriesLeaderBoard = () => {
  const { id } = useParams();
  const [listLeaderBoard, setListLeaderBoard] = useState();
  const [seriesData, setSeriesData] = useState();
  const [partnerData, setPartnerData] = useState();

  /*API Call For Partner Data  */

  useEffect(() => {
    listApi(APIS.LIST_SERIES_LEADERBOARD, { seriesId: id }).then((result) => {
      setListLeaderBoard(result?.data);
      setSeriesData(result?.seriesData);
      setPartnerData(result?.partnerData);
    });
    const interval = setInterval(() => {
      listApi(APIS.LIST_SERIES_LEADERBOARD, { seriesId: id }).then((result) => {
        setListLeaderBoard(result?.data);
      });
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  /*Return */

  return (
    <>
      <NavBar
        fromContestLeaderBoard
        id={id}
        buttonData={SERIES_BOARD_BUTTON}
        showBack
      />

      {listLeaderBoard?.length === 0 ? (
        <NoDataFound message={NO_DATA_FOUND.SERIES} />
      ) : (
        <>
          {!!seriesData?.name ? (
            <TableHeading headingName={seriesData?.name} />
          ) : (
            <BoardHeadingSkeleton />
          )}
          {!!listLeaderBoard ? (
            <LeaderBoardTable
              listLeaderBoard={listLeaderBoard}
              partnerData={partnerData}
            />
          ) : (
            <LeaderBoardSkeleton />
          )}
        </>
      )}
    </>
  );
};
export default SeriesLeaderBoard;
