import React from "react";

const ProfileAvatar = ({ profileImg, name }) => {
  return (
    <>
      {profileImg ? (
        <img
          class="rounded-full w-7 h-7 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-10 lg:h-10 xl:w-10 xl:h-10 2xl:w-10 2xl:h-10"
          src={profileImg}
          alt="Rounded avatar"
        />
      ) : (
        <div class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full ">
          <span class="font-medium text-gray-600 uppercase">{name[0]}</span>
        </div>
      )}
    </>
  );
};

export default ProfileAvatar;
