export const NO_DATA_FOUND = {
  LEADER_BORAD: "NO LEADER BORAD DATA FOUND",
  CONTEST: "NO CONTEST FOUND",
  SERIES:"NO  SERIES FOUND"
};

export const CONTEST_BOARD_BUTTON = [
  {
    label: "Contest",
    active: true,
    navigate: "cb",
  },
];
export const SERIES_BOARD_BUTTON = [
  {
    label: "Series",
    active: true,
    navigate: "sr",
  },
];
export const CONTEST_PAGE_BUTTON = [
  {
    label: "Leaderboard",
    active: false,
    navigate: "lb",
  },
  {
    label: "Contests",
    active: true,
    navigate: "ct",
  },
  {
    label: "Series",
    active: false,
    navigate: "sr",
  },
];

export const PARTNER_BOARD_BUTTON = [
  {
    label: "Leaderboard",
    active: true,
    navigate: "lb",
  },
  {
    label: "Contests",
    active: false,
    navigate: "ct",
  },
  {
    label: "Series",
    active: false,
    navigate: "sr",
  },
];
export const SERIES_PAGE_BUTTON = [
  {
    label: "Leaderboard",
    active: false,
    navigate: "lb",
  },
  {
    label: "Contests",
    active: false,
    navigate: "ct",
  },
  {
    label: "Series",
    active: true,
    navigate: "sr",
  },
];