import React, { useLayoutEffect } from "react";
import { ROUTES } from "./routes";
import { Home, LeaderBoard, Contest, ContestLeaderBoard } from "../pages";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Series from "../pages/series";
import SeriesLeaderBoard from "../pages/seriesLeaderBoard";

//Scroll to top on every route change
const ScrollToTop = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route>
            <Route path={ROUTES.HOME} element={<Home />} />
            <Route path={ROUTES.LEADERBOARD} element={<LeaderBoard />} />
            <Route path={ROUTES.CONTEST} element={<Contest />} />
            <Route path={ROUTES.SERIES} element={<Series />} />
            <Route
              path={ROUTES.CONTEST_BOARD}
              element={<ContestLeaderBoard />}
            />
            <Route path={ROUTES.SERIES_BOARD} element={<SeriesLeaderBoard />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default Router;
