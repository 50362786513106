import React from "react";

const TableHeading = ({ headingName, label = "" }) => {
  return (
    <div class="px-3 py-5 sm:px-5">
      <h2 class="text-[#0369a1] font-medium leading-tight  text-center text-2xl sm:text-3xl md:text-4xl lg:text-4xl xl:text-4xl ">
        {headingName}
      </h2>
      <h6 class="text-[#0369a1] font-medium leading-tight text-center	text-2xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-3xl ">
        {label} Leaderboard
      </h6>
    </div>
  );
};
export default TableHeading;
