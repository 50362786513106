import React, { useEffect, useRef, useState } from "react";
import RankAvatar from "../../avatar/rankAvatar";
import ProfileAvatar from "../../avatar/profileAvatar";
import ScoreAvatar from "../../avatar/scoreAvatar";

const LeaderBoardTable = ({ listLeaderBoard, partnerData }) => {
  const [scrollPos, setScrollPos] = useState(0);
  const scrollRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const scrollHeight = scrollRef.current.scrollHeight;
      const clientHeight = scrollRef.current.clientHeight;
      const maxScrollTop = scrollHeight - clientHeight;
      let newScrollPos = scrollPos + 1;
      if (newScrollPos > maxScrollTop) {
        newScrollPos = 0;
      }
      setScrollPos(newScrollPos);
      scrollRef.current.scrollTop = newScrollPos;
    }, 10); // scroll every second
    return () => {
      clearInterval(interval);
    };
  }, [scrollPos]);

  return (
    <div class="px-3 sm:px-5">
      <div
        ref={scrollRef}
        class="relative overflow-x-auto shadow-md sm:rounded-lg  h-screen overflow-y-scroll"
        style={{
          background:
            partnerData?.leaderboardUiBackground !== null &&
            partnerData?.leaderboardUiBackground !== undefined
              ? `linear-gradient(0deg, rgba(255, 255, 255, 0.54), rgb(11 11 11 / 20%)),url(${partnerData?.leaderboardUiBackground})`
              : "",
        }}
      >
        <table class="w-full text-sm text-left text-gray-500">
          <thead class="h-16  w-full text-xs text-white uppercase bg-[#ed5474]">
            <tr>
              <th
                scope="col"
                class="px-5 py-3
               sm:px-10  md:px-10  lg:px-10  xl:px-10  2xl:px-10 "
              >
                #
              </th>
              <th
                scope="col"
                class="px-6 py-3
              "
              >
                Name
              </th>
              <th
                scope="col"
                class="px-6 py-3
              "
              >
                Points
              </th>
            </tr>
          </thead>
          <tbody>
            {listLeaderBoard?.map((partner, index, arr) => {
              return (
                <tr class="w-full  border-b cursor-pointer	hover:bg-gray-50 ">
                  <td
                    class="px-3 py-4 text-gray-900
                  sm:px-6  md:px-6  lg:px-6  xl:px-6  2xl:px-6 "
                  >
                    <RankAvatar rank={partner?.rank} />
                  </td>
                  <th
                    scope="row"
                    class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap justify-start"
                  >
                    <ProfileAvatar
                      profileImg={partner?.userData?.profileImg}
                      name={partner?.userData?.name}
                    />

                    <div class="pl-3">
                      <div class=" text-xs sm:text-base font-semibold">
                        {partner?.userData?.name}
                      </div>
                    </div>
                  </th>
                  <td class="px-6 py-4 text-gray-900 ">
                    <ScoreAvatar rank={partner?.rank} score={partner?.score} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LeaderBoardTable;
